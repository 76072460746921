<template>
	<div class="container">
		<el-row :gutter="20" class="items-box" v-loading="statisticLoading">
			<el-col :span="8">
				<div to="/personnelManagement" class="item">
					<div class="item-left">
						<p class="item-num">{{statistic.author_num}}</p>
						<p class="item-title">创作者</p>
					</div>
					<div class="item-right icon1">
						<i class="iconfont el-icon-user"></i>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div to="/serveUser?is_auth=2" class="item">
					<div class="item-left">
						<p class="item-num">{{statistic.user_num}}</p>
						<p class="item-title">用户数</p>
					</div>
					<div class="item-right icon2">
						<i class="iconfont el-icon-s-custom"></i>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div to="/order?status=4" class="item">
					<div class="item-left">
						<p class="item-num">{{statistic.content_num}}</p>
						<p class="item-title">已发布内容数</p>
					</div>
					<div class="item-right icon3">
						<i class="iconfont el-icon-s-order"></i>
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="canvas-schart">
			<div class="schart-head">
				<div class="schart-title">创作者入驻趋势</div>
				<div class="schart-handel">
					<el-button :class="['handel-btn', query.type==1?'active-handel-btn':'']" @click="changType(1)">近7天
					</el-button>
					<el-button :class="['handel-btn', query.type==2?'active-handel-btn':'']" @click="changType(2)">近30天
					</el-button>
				</div>
			</div>
			<div id="customerChart" style="width: 100%;height:400px;padding: 50px 0;" v-loading="graphLoading"></div>
		</div>
	</div>
</template>

<script>
	import request from '@/utils/request.js'
	import {
		reactive,
		ref,
		watch,
		onMounted,
		onUnmounted,
		inject,
	} from 'vue'
	export default {
		setup() {
			let myChart;
			let echarts = inject("ec"); //引入
			onMounted(() => { //需要获取到element,所以是onMounted的Hook
				myChart = echarts.init(document.getElementById("customerChart"));
				// 绘制图表
			});
			onUnmounted(() => {
				myChart.dispose()
			})
			let statistic = ref({

				}),
				query = reactive({
					type: 1
				}),
				graphLoading = ref(false),
				statisticLoading = ref(false),
				options = reactive({
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['创作者', '用户数', '已发布内容数'],
						selected: {
							'用户数': false,
							'已发布内容数': false
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '创作者',
							type: 'line',
							stack: '总量',
							data: []
						},
						{
							name: '用户数',
							type: 'line',
							stack: '总量',
							data: []
						},
						{
							name: '已发布内容数',
							type: 'line',
							stack: '总量',
							data: []
						},
					]
				})
			const getGraph = () => {
				graphLoading.value = true
				request({
					url: '/admin/index/getGraph',
					method: 'get',
					params: {
						...query
					},
				}).then((res) => {
					graphLoading.value = false
					if (res.code == 200) {
						options.xAxis.data = res.data.date
						options.series[0].data = res.data.author_num
						options.series[1].data = res.data.user_num
						options.series[2].data = res.data.content_num
						myChart.setOption(options)
					}
				}).catch(() => {
					graphLoading.value = false
				});
			}
			const getStatistic = () => {
				statisticLoading.value = true
				request({
					url: '/admin/index/getStatistic',
					method: 'get',
				}).then((res) => {
					statisticLoading.value = false
					if (res.code == 200) {
						statistic.value = res.data
					}
				}).catch(() => {
					statisticLoading.value = false
				});
			}
			const changType = (val) => {
				query.type = val
			}
			watch(
				() => query.type,
				() => {
					getGraph()
				}
			)
			getGraph()
			getStatistic()
			return {
				getStatistic,
				getGraph,
				statistic,
				query,
				changType,
				options,
				graphLoading,
				statisticLoading
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item {
		height: 160px;
		display: flex;
		box-shadow: 0px 5px 55px rgba(242, 242, 242, 1);
		border-radius: 16px;
		box-sizing: border-box;
		padding: 20px 40px;
		justify-content: space-between;
	}

	.item-num {
		font-weight: 700;
		font-size: 44px;
		padding-top: 20px;
		color: #333333;
	}

	.item-title {
		font-weight: 700;
		font-size: 14;
		color: rgb(102, 102, 102);
	}

	.item-right {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
	}

	.item-right .iconfont {
		font-size: 30px;
	}

	.icon1 {
		background: #f0f1fd;
	}

	.icon1 .iconfont {
		color: #666ee8;
	}

	.icon2 {
		background: #fff0f0;
	}

	.icon2 .iconfont {
		color: #ff6262;
	}

	.icon3 {
		background: #eafaf5;
	}

	.icon3 .iconfont {
		color: #28d094;
	}

	.icon4 {
		background: #fffae9;
	}

	.icon4 .iconfont {
		color: #ffc71c;
	}

	.canvas-wrapper {
		width: 100%;
	}

	.canvas-schart {
		width: 100%;
		background: #fff;
		border-radius: 16px;
		box-sizing: border-box;
		box-shadow: 0px 5px 20px rgba(204, 204, 204, 0.349019607843137);
		margin-top: 20px;
	}

	.schart {
		width: 100%;
		height: 436px;
	}

	.schart-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 2px solid #f2f2f2;
		height: 60px;
		box-sizing: border-box;
		padding: 0 50px;
	}

	.schart-title {
		font-weight: 700;
		font-style: normal;
		font-size: 16px;
		color: rgb(51, 51, 51);
	}

	.handel-btn {
		width: 90px;
		height: 40px;
		border-radius: 20px;
	}

	.active-handel-btn {
		color: #fff;
		background-color: #409eff;
		border-color: #409eff;
	}
</style>
